<template>
  <section>
    <div class="main-content">
      <div class="pawn-detail-content">
        <div class="header-bar-asset-config">
          <img class="back-btn" src="@/assets/img/prev.svg" alt />
          <div class="title-asset-config">SỔ QUỸ VÍ ĐIỆN TỬ</div>
        </div>
        <div class="filter is-gapless columns mar-10">
          <div class="column is-7">
            <div class="columns is-gapless dropdown-search-custom">
              <b-field class="column is-4">
                <b-datepicker
                  class=""
                  :locale="locale"
                  placeholder="Từ ngày đến ngày"
                  icon-right="calendar-today"
                  :years-range="[-100, 100]"
                  trap-focus
                  v-model="selectDate"
                  range
                  :max-date="maxDate"
                >
                </b-datepicker>
              </b-field>
              <b-field class="column is-5">
                <v-select
                  ref="model"
                  placeholder="PGD Nhận"
                  class="style-chooser"
                  label="Name"
                  v-model="shopCode"
                  :options="listShop"
                  :reduce="(option) => option.Code"
                  @input="loadseletedShop"
                  :disabled="isDisabled"
                >
                  <div slot="no-options">Không có kết quả phù hợp</div>
                </v-select>
              </b-field>
              <b-field class="column is-3">
                <v-select
                  ref="modelBank"
                  placeholder="Ngân hàng"
                  class="style-chooser"
                  label="Value"
                  v-model="banker"
                  :options="listBank"
                  :reduce="(option) => option.Code"
                  @input="selectBank"
                >
                  <div slot="no-options">Không có kết quả phù hợp</div>
                </v-select>
              </b-field>
            </div>
          </div>
          <div class="columns mar-le-10">
            <b-field class="column is-2">
              <b-button
                icon-left="filter"
                label="Tìm kiếm"
                @click="LoadCashBook"
                style="border: 1px solid #ebedf2 !important"
              ></b-button>
            </b-field>
          </div>
          <div class="column is-1 pos-ab">
            <div class="mar-top-5 export-excel" @click="ExportExcel()">
              <img src="@/assets/img/excel.svg" alt />
              <span>Xuất Excel</span>
            </div>
          </div>
        </div>
        <div class="box-sumarry is-gapless columns mar-10">
          <div class="column is-12 box-custom">
            <div class="columns is-gapless">
              <CashBookSummary
                :data="dataSum"
                :category="lstCategory"
                :isactive="isActive"
                :text="text"
              />
            </div>
          </div>
        </div>
        <div class="custom-list-cashbook">
          <CashBookItemFilter
            :category="lstCategory"
            @FilterByCash="FilterByCashBook"
          />
        </div>
        <div>
          <PagingItem :filter="filter" :total="total" />
          <div class="header-table">
            <div class="order">#</div>
            <div class="col">
              <div class="columns">
                <div class="column is-1 text-align">Ngày</div>
                <div class="column is-1">Mã HĐ</div>
                <div class="column is-2 custom-w-12">Tên đối tượng</div>
                <div class="column is-1">Loại hình</div>
                <div class="column is-1">Loại giao dịch</div>
                <div class="column is-2">Diễn giải</div>
                <div class="column is-1 text-align">Thu</div>
                <div class="column is-1 text-align">Chi</div>
                <div class="column is-2 custom-w-10">Mã phiếu</div>
              </div>
            </div>
          </div>
          <div class="main-table tbl-bank-transfer-list">
            <div
              class="item-table"
              v-for="(item, idx) in listTran"
              :key="item.Id"
            >
              <CashBookItem :idx="idx" :data="item" :offset="filter.offset" />
            </div>
          </div>
          <PagingItem :filter="filter" :total="total" />
        </div>
      </div>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
  </section>
</template>
<script>
import FundTransferService from "@/modules/fundtransfers/services/FundServices.vue";
import UserService from "@/modules/user/services/UserService.vue";
import CashBookSummary from "@/modules/cashbooks/components/CashBookSummary.vue";
import CashBookItemFilter from "@/modules/cashbooks/components/CashBookItemFilter.vue";
import CashBookService from "@/modules/cashbooks/services/CashBookServices.vue";
import ConvertToDateTime from "@/helpers/ConvertDatetime.vue";
import CashBookItem from "@/modules/cashbooks/components/CashBookItem.vue";
import Enum from "@/helpers/Enum.vue";
import ToastHelper from "@/helpers/OpenToast.vue";
//import store from "@/store";
export default {
  components: { CashBookSummary, CashBookItemFilter, CashBookItem },
  data() {
    const today = new Date();
    return {
      text: "ví điện tử",
      locale: "vi-ve",
      total: 0,
      selectDate: [],
      filter: {
        dateFormat: "yyyy-MM-dd",
        fromDate: null,
        toDate: null,
        office: null,
        locale: "en",
        currencyCode: "VND",
        paymentType: 4,
        receiveMethod: null,
        staffCode: null,
        offset: 0,
        limit: 5000,
      },
      shopCode: null,
      banker: null,
      isLoading: false,
      listShop: [],
      lstUser: [],
      filUser: {
        ShortName: null,
      },
      listTran: [],
      lstCategory: [],
      isDisabled: false,
      shopFil: null,
      dataSum: null,
      isActive: false,
      cashBookFilter: null,
      listBank: [],
      maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      ShopName: "",
    };
  },
  mounted() {
    let _self = this;
    // _self.checkURL();
    _self.$parent.$refs.info.isDis = false;
    //let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
    _self.GetListPGD();
    _self.GetListBank();
    _self.Get_ListMethodCashBook();
    _self.SetDefaultData();
    // _self.LoadCashBook();
  },
  methods: {
    selectBank(val) {
      let _self = this;
      _self.filter.receiveMethod = null;
      if (val != null)
        _self.filter.receiveMethod = _self.listBank.filter(
          (x) => x.Code == val
        )[0].Value;
    },
    loadseletedShop(val) {
      let _self = this;
      _self.shopFil = val;
    },
    pageChanged() {
      let _self = this;
      _self.LoadCashBook();
      _self.$router.push({
        name: "ListCashBookWallet",
        query: {
          Offset: _self.filter.offset,
          Limit: _self.filter.limit,
          Search: _self.filter.search,
        },
      });
    },
    async GetListPGD() {
      let _self = this;
      _self.isLoading = true;
      let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
      console.log("shopSelected", shopSelected);
      let result = await FundTransferService.Get_ListPGDNew();
      _self.listShop = result.Data;
      _self.shopCode = shopSelected.Code;
      _self.shopFil = Enum.OFFICE.HO;
      _self.ShopName = shopSelected.Name;
      _self.isDisabled = false;
      _self.cashBookFilter = null;
      if (shopSelected.BusinessOfficeCode != Enum.OFFICE.HO) {
        _self.isDisabled = true;
        _self.shopCode = shopSelected.Code;
        _self.shopFil = shopSelected.BusinessOfficeCode;
      }
      _self.isLoading = false;
    },
    async LoadCashBook() {
      let _self = this;
      _self.isLoading = true;
      if (_self.filter.receiveMethod == null) {
        ToastHelper.OpenToast("Bạn chưa chọn ngân hàng cần xem!", "is-warning");
        _self.isLoading = false;
        return;
      }
      if (_self.selectDate[0] == null) {
        ToastHelper.OpenToast("Bạn chưa chọn ngày xem dữ liệu!", "is-warning");
        _self.isLoading = false;
        return;
      } else {
        _self.filter.fromDate = ConvertToDateTime.ConvertDateToString2(
          _self.selectDate[0]
        );
        _self.filter.toDate = ConvertToDateTime.ConvertDateToString2(
          _self.selectDate[1]
        );
      }
      _self.isActive = false;
      _self.filter.office = _self.shopFil;
      // _self.filter.receiveMethod = _self.banker;
      let cal = ConvertToDateTime.CalcularDate(
        _self.filter.toDate,
        _self.filter.fromDate
      );
      if (cal > 1) {
        ToastHelper.OpenToast(
          "Bạn không thể xem dữ liệu vượt quá 1 ngày!",
          "is-warning"
        );
        _self.isLoading = false;
        return;
      }
      if (_self.filter.fromDate != null) {
        _self.filter.paymentType = 4;
        let result = await CashBookService.ListCashBook(_self.filter);
        _self.dataSum = null;
        _self.listTran = null;
        _self.total = 0;
        if (result != null && result != "") {
          _self.dataSum = JSON.parse(JSON.stringify(result));
          _self.isActive = true;
          _self.listTran = _self.dataSum.Transactions.PageItems;
          _self.total = result.Transactions.TotalFilteredRecords;
          if (_self.cashBookFilter != null) {
            _self.listTran = _self.dataSum.Transactions.PageItems.filter(
              (x) => x.BusinessType == _self.cashBookFilter
            );
            _self.isActive = false;
            _self.total = _self.listTran.length;
          }
        }
      }
      _self.isLoading = false;
    },
    async GetListBank() {
      let _self = this;
      let result = await FundTransferService.Get_ListBank();
      let dataBank = result.Data.filter((x) => x.Id != 265);
      _self.listBank = dataBank;
    },
    async GetEmpoyee() {
      let _self = this;
      //let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
      let result = await UserService.Get_ListEmployeePGD(
        _self.filUser.ShortName
      );
      _self.lstUser = result;
    },
    async SetDefaultData() {},
    async Get_ListMethodCashBook() {
      let _self = this;
      let result = await CashBookService.Get_ListMethodCashBook();
      _self.lstCategory = result;
    },
    async ExportExcel() {
      let _self = this;
      _self.isLoading = true;
      if (_self.filter.receiveMethod == null) {
        ToastHelper.OpenToast("Bạn chưa chọn ngân hàng cần xem!", "is-warning");
        _self.isLoading = false;
        return;
      }
      if (_self.selectDate[0] == null) {
        ToastHelper.OpenToast("Bạn chưa chọn ngày xuất dữ liệu!", "is-warning");
        _self.isLoading = false;
        return;
      } else {
        _self.filter.fromDate = ConvertToDateTime.ConvertDateToString2(
          _self.selectDate[0]
        );
        _self.filter.toDate = ConvertToDateTime.ConvertDateToString2(
          _self.selectDate[1]
        );
      }
      let cal = ConvertToDateTime.CalcularDate(
        _self.filter.toDate,
        _self.filter.fromDate
      );
      if (cal > 1) {
        ToastHelper.OpenToast(
          "Bạn không thể xuất dữ liệu vượt quá 1 ngày",
          "is-warning"
        );
        _self.isLoading = false;
        return;
      }
      _self.filter.office = _self.shopFil;
      let filterModel = _self.filter;
      let result = await CashBookService.ExportExcelCashBook(
        filterModel,
        Enum.NAME_EXPORT.EPay,
        _self.ShopName
      );
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([result]));
      link.setAttribute(
        "download",
        "SQEPay-" + _self.ShopName + "_" + date + " " + time + ".xlsx"
      );
      document.body.appendChild(link);
      link.click();
      _self.isLoading = false;
    },
    async FilterByCashBook(val) {
      let _self = this;
      _self.cashBookFilter = val;
      _self.isLoading = true;
      if (_self.cashBookFilter != null && _self.listTran != null) {
        _self.listTran = _self.dataSum.Transactions.PageItems.filter(
          (x) => x.BusinessType == _self.cashBookFilter
        );
        _self.isActive = false;
        _self.total = _self.listTran.length;
      } else {
        _self.listTran = _self.dataSum.Transactions.PageItems;
        _self.total = _self.listTran.length;
      }
      _self.isLoading = false;
    },
  },
};
</script>
<style scoped>
</style>
